import useSoundLibrary from "use-sound";
import { soundSetting as serviceSoundSetting } from "service";
import { TripleSevenLastHit, } from "assets/audio";

export const useSound = (soundEnabled) => {
  const soundSetting = {
    ...serviceSoundSetting,
    soundEnabled
  };
  const [playTripleSevenLastStrike] = useSoundLibrary(TripleSevenLastHit, soundSetting);

  return {
		playTripleSevenLastStrike
  };
}

export default useSound